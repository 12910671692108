import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Header, SectionResources, resourceCategoryTypes } from "components";
import { Layout } from "containers";
import { getPageFilters } from "utils/page";

const TemplatePageResources = ({
  data: { resourceCategories, resources },
  location,
}) => {
  const layoutProps = {
    seoProps: {
      title: "Remote Work Resources",
    },
  };

  const headerProps = {
    minHeight: "auto",
    heading:
      "Hand-picked resources that help you build great things, from anywhere",
  };

  const sectionResourcesProps = {
    filters: getPageFilters(resourceCategories, "/resources"),
    location,
    resources,
  };

  return (
    <Layout {...layoutProps}>
      <Header {...headerProps} />
      <SectionResources {...sectionResourcesProps} />
    </Layout>
  );
};

TemplatePageResources.propTypes = {
  data: PropTypes.shape({
    // config: PropTypes.shape({
    //   header: PropTypes.shape({
    //     heading: PropTypes.string,
    //   }),
    //   id: PropTypes.string,
    //   seo: PropTypes.shape({
    //     description: PropTypes.string,
    //     title: PropTypes.string,
    //   }),
    // }),
    resourceCategories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          category: resourceCategoryTypes,
        })
      ),
    }),
    resources: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            data: PropTypes.shape({
              category: resourceCategoryTypes,
              date: PropTypes.string,
              description: PropTypes.string,
              id: PropTypes.string,
              image: PropTypes.oneOfType([
                PropTypes.shape({
                  localFiles: PropTypes.arrayOf(
                    PropTypes.shape({
                      childImageSharp: PropTypes.shape({
                        fluid: PropTypes.shape({
                          base64: PropTypes.string,
                          aspectRatio: PropTypes.number,
                          src: PropTypes.string,
                          srcSet: PropTypes.string,
                          sizes: PropTypes.string,
                        }),
                      }),
                      name: PropTypes.string,
                    })
                  ),
                }),
                PropTypes.string,
              ]),
              link: PropTypes.string,
              title: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

export const resourcesByCategoryQuery = graphql`
  query ResourcesByCategory($category: String!) {
    # config: allPagesYaml {
    #   edges {
    #     node {
    #       header {
    #         heading
    #       }
    #       id
    #       seo {
    #         description
    #         title
    #       }
    #     }
    #   }
    # }
    resourceCategories: allAirtable(
      filter: { data: { isActive: { eq: true } }, table: { eq: "Resources" } }
    ) {
      group(field: data___category) {
        category: fieldValue
      }
    }
    resources: allAirtable(
      filter: {
        data: { isActive: { eq: true }, category: { eq: $category } }
        table: { eq: "Resources" }
      }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          data {
            category
            date(formatString: "MMMM DD, YYYY")
            description
            id
            image {
              localFiles {
                childImageSharp {
                  fluid(
                    maxWidth: 600
                    quality: 90
                    traceSVG: { color: "#ced0d4" }
                  ) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
                name
              }
            }
            link
            title
          }
          id
        }
      }
    }
  }
`;

export default TemplatePageResources;
